@import "~bootstrap/scss/bootstrap";

.CryptoLinks {
  // padding-top: 20px;

  .controls .iconn {
    position: relative;
    color: blue;
    display: none;
  }

  .controls:hover .iconn {
    display: inline;
    padding-left: 5px;
    color: $green-600;
  }

  a:visited {
    color: $indigo-700;
  }

  a:hover {
    color: $green-600;
  }
}
