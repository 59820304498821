.table {
  font-size: smaller;
  text-align: center;
}

.gain {
  color: #4fdc8a;
}

.lose {
  color: #ef5350;
}

.center {
  text-align: center;
}
